import { AnimatePresence, motion } from "framer-motion";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { type ReactNode, cloneElement } from "react";
import { type RecipeVariantProps, css, cva } from "~/design/generated/css";
import { nano } from "~/utils/nanoid";

const toastsAtom = atom<Map<string, Toast>>(new Map());

export type Toast = {
  id: string;
  node: ReactNode;
  dismiss: () => void;
};

export type ToastOptions = {
  duration?: number;
  id?: string;
};

export type UseToasterReturn = {
  add: (node: ReactNode, options?: ToastOptions) => string;
  remove: (id: string) => void;
  clear: () => void;
};

const toasterStyle = cva({
  base: {
    isolation: "isolate",
    display: "flex",
    flexDirection: "column",
    h: "100%",
    w: "100%",
    gap: 1,
  },
  variants: {
    position: {
      top: {
        justifyContent: "flex-start",
        alignItems: "center",
      },
      bottom: {
        justifyContent: "flex-end",
        alignItems: "center",
      },
      "top-left": {
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      "top-right": {
        justifyContent: "flex-start",
        alignItems: "flex-end",
      },
      "bottom-left": {
        justifyContent: "flex-end",
        alignItems: "flex-start",
      },
      "bottom-right": {
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
    },
  },
  defaultVariants: {
    position: "bottom-right",
  },
});

export type ToasterProps = RecipeVariantProps<typeof toasterStyle>;

export function Toaster({ position = "bottom-right" }: ToasterProps) {
  const atomToasts = useAtomValue(toastsAtom);
  const isTop = position?.includes("top");
  const toasts = Array.from(atomToasts.values());

  return (
    <motion.div
      layout
      layoutRoot
      className={css({
        pointerEvents: "none",
        position: "fixed",
        inset: 0,
        zIndex: 99999,
        p: 2,
      })}
    >
      <div className={toasterStyle({ position })}>
        <AnimatePresence initial={false} mode="popLayout">
          {toasts.map((toast, toastIndex) => {
            return (
              <motion.div
                key={toast.id}
                className={css({
                  pointerEvents: "auto",
                })}
                initial={{
                  y: isTop ? -32 : 32,
                  x: 0,
                  scale: 0.9,
                  opacity: 1,
                }}
                layout="position"
                animate={{
                  y: 0,
                  x: 0,
                  scale: 1,
                  opacity: 1,
                }}
                transition={{
                  type: "tween",
                  ease: "backOut",
                }}
                exit={{
                  opacity: 0,
                  scale: 0.9,
                  y: isTop ? -32 : 32,
                }}
                style={{
                  zIndex: 999999 + toastIndex,
                }}
              >
                {cloneElement(toast.node, {
                  dismiss: toast.dismiss,
                  id: toast.id,
                })}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

export function useToaster(): UseToasterReturn {
  const setToasts = useSetAtom(toastsAtom);

  const add = (node: ReactNode, options?: ToastOptions) => {
    const id = options?.id || nano();
    const dismiss = () => remove(id);
    const toast = { id, node, dismiss };

    setToasts((prevToasts) => {
      return new Map(prevToasts.set(id, toast));
    });

    if (options?.duration && options?.duration > 0) {
      setTimeout(dismiss, options.duration);
    }

    return toast.id;
  };

  const remove = (id: string) => {
    setToasts((prevToasts) => {
      prevToasts.delete(id);
      return new Map(prevToasts);
    });
  };

  const clear = () => {
    setToasts(new Map());
  };

  return { add, remove, clear };
}
