import type { ReactNode } from "react";
import { css } from "~/design/generated/css";

export type ToastProps = {
  children?: ReactNode;
  dismiss?: () => void;
  id?: string;
};

export function Toast({ children, dismiss, id }: ToastProps) {
  return (
    <div
      className={css({
        bg: "elevation.dialog",
        p: 2,
        rounded: "sm",
        fontSize: "md",
      })}
    >
      {children}
    </div>
  );
}
