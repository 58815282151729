import { useEffect } from "react";
import { useRootData } from "~/hooks/useRootData";
import { Toast } from "./Toast";
import { useToaster } from "./Toaster";

export function ToastRedirect() {
  const { toast } = useRootData();
  const toaster = useToaster();

  useEffect(() => {
    if (toast) {
      toaster.add(<Toast>{toast.message}</Toast>, {
        id: toast.id,
        duration: toast.duration,
      });
    }
  }, [toast]);

  return null;
}
