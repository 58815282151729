import { isRouteErrorResponse } from "@remix-run/react";
import type { ReactNode } from "react";
import { flex } from "~/design/generated/patterns";
import { JsonViewer } from "./JsonViewer";

function ErrorLayout({ children }: { children: ReactNode }) {
  return (
    <div
      className={flex({
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      })}
    >
      {children}
    </div>
  );
}

export function ErrorDisplay({ error }: { error: unknown }) {
  if (isRouteErrorResponse(error)) {
    return (
      <ErrorLayout>
        <h1>Oops</h1>
        <p>Status: {error?.status}</p>
        <p>{error?.data?.message}</p>
        <JsonViewer json={error} />
      </ErrorLayout>
    );
  }

  return (
    <ErrorLayout>
      <h1>Uh oh ...</h1>
      <p>Something went wrong.</p>
    </ErrorLayout>
  );
}
