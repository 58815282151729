import { getHintUtils } from "@epic-web/client-hints";
import { clientHint as timeZoneHint } from "@epic-web/client-hints/time-zone";

const hintsUtils = getHintUtils({
  timeZone: timeZoneHint,
});

type ClientHintsProps = {
  nonce?: string;
};

export const { getHints } = hintsUtils;

export default function ClientHints({ nonce = "123" }: ClientHintsProps) {
  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: hintsUtils.getClientHintCheckScript(),
      }}
    />
  );
}
