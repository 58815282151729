import { useLocation } from "@remix-run/react";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useRootData } from "~/hooks/useRootData";

export function PosthogAnalytics() {
  const { user } = useRootData();
  const location = useLocation();

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location.key]);

  useEffect(() => {
    if (user?.id && user.role !== "ANONYMOUS") {
      posthog.identify(user.id, {
        email: user.email,
        username: user.username,
      });

      return () => {
        posthog.reset();
      };
    }
  }, [user?.id]);

  return null;
}
