import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { useChangeLanguage } from "remix-i18next/react";
import { FlagsProvider } from "flagged";
import { typedjson, useTypedLoaderData } from "remix-typedjson";
import ClientHints, { getHints } from "./components/ClientHints";
import { ErrorDisplay } from "./components/ErrorDisplay";
import { PublicEnv } from "./components/PublicEnv";
import { ToastRedirect } from "./components/ToastRedirect";
import { PosthogAnalytics } from "./components/PosthogAnalytics";
import { Toaster } from "./components/Toaster";
import { getAppearance } from "./services/appearance.server";
import { getSessionId, isAuthenticated } from "./services/auth.server";
import { getPublicEnv } from "./services/environment.server";
import { getToast } from "./services/toast.server";
import { css } from "~/design/generated/css";

import styles from "~/design/generated/styles.css?url";
import { getUserFavorites } from "./models/model.server";
import { getSettings } from "./services/settings.server";
import { getRates } from "./services/rates.server";

export const handle = {
  i18n: ["common"],
};

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: "/fonts/inter/stylesheet.css" },
    { rel: "stylesheet", href: "/fonts/ibm-plex-mono/stylesheet.css" },
    { rel: "stylesheet", href: "/styles/easy-crop.css" },
  ];
};

export function meta() {
  return [
    { charSet: "utf-8" },
    { title: "Placeholder" },
    { name: "mobile-web-app-capable", content: "yes" },
    { name: "theme-color", content: "???" },
    { name: "robots", content: "noindex" },
  ];
}

export async function loader({ request, context }: LoaderArgs) {
  const user = await isAuthenticated(context, request);
  const { toast, headers } = await getToast(request);
  const appearance = await getAppearance(request);
  const sessionId = await getSessionId(context, request);
  const publicEnv = getPublicEnv(context.env);
  const hints = getHints(request);
  const favorites = await getUserFavorites(context, user?.id);
  const settings = await getSettings(request, user);
  const rates = await getRates();

  return typedjson(
    {
      favorites,
      sessionId,
      appearance,
      publicEnv,
      toast,
      user,
      hints,
      settings,
      rates,
    },
    { headers },
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <ErrorDisplay error={error} />;
}

export default function App() {
  const { settings, appearance, publicEnv } = useTypedLoaderData<typeof loader>();

  useChangeLanguage(settings.lang);

  return (
    <html
      lang={settings.lang}
      dir="ltr"
      data-color-mode={appearance.colorMode}
      data-panda-theme={appearance.theme !== "gray" ? appearance.theme : undefined}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body
        className={css({
          bg: "elevation.page",
          color: "neutral.foreground",
          fontFamily: "body",
          "--horizontal-bar-height": "60px",
          "--gap": "8px",
          "--gutter": "var(--gap)",
          "--catalog-filters-width": "270px",
        })}
      >
        <FlagsProvider features={{}}>
          <Outlet />
        </FlagsProvider>
        <PosthogAnalytics />
        <PublicEnv {...publicEnv} />
        <Toaster />
        <ToastRedirect />
        <ClientHints />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
